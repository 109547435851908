
import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

//Assets
import KtlLogo from "./ktl-logo-mini.svg";
import DoorLogo from "./door-logo.svg";
import HooliGate from './hooliGate.svg';

//Local imports
import MonitorCuadrante from '../components/monitorCuadrante';
import GateMonitorGrilla from '../components/gateMonitorGrilla';
import DropSelect from "../components/selectDrop.js";

import { GrillaSemanaProvider } from '../context/grillaSemanaContext';
import { PuestoSelectContextProvider } from '../context/puestoSelectContext';


export default function MonitorGate() {
    let classes = useStyles();

    const [semanaSeleccionada, setSemanaSeleccionada] = useState(null);
    const [puestoSeleccionado, setPuestoSeleccionado] = useState(null);

    const [monitorCuadrante, setMonitorCuadrante] = useState({})
    const [puestoSelectCtx, setPuestoSelectCtx] = useState({})

    const handleChangeSemana = (v: any) => { setSemanaSeleccionada(v) };

    const handlePuestoChange = (v: any) => { setPuestoSeleccionado(v) };

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.headerDoorLogoContainer}>
                    <img
                        src={DoorLogo} alt=""
                        className={classes.doorLogo}
                    />
                    <p className={classes.title} >Monitoreo de Ingresos y Egresos</p>
                </div>

                <div className={classes.ktlLogoContainer}>
                    <img className={classes.ktlLogo} src={KtlLogo} alt="" />
                    <img className={classes.hooliLogo} src={HooliGate} alt="" />
                </div>
            </div>

            <div className={classes.bodyContainer}>
                <GrillaSemanaProvider value={[monitorCuadrante, setMonitorCuadrante]}>
                    <div className={classes.bodyLeftSideContainer}>
                        <PuestoSelectContextProvider value={[puestoSelectCtx, setPuestoSelectCtx]}>
                            <DropSelect onSemanaChange={handleChangeSemana} onPuestoChange={handlePuestoChange} />
                            <GateMonitorGrilla semanaSeleccionada={semanaSeleccionada} puestoSeleccionado={puestoSeleccionado} />
                        </PuestoSelectContextProvider>
                    </div>
                    <div className={classes.bodyRightSideContainer}>
                        <MonitorCuadrante semanaSeleccionada={semanaSeleccionada} puestoSeleccionado={puestoSeleccionado} />
                    </div>
                </GrillaSemanaProvider>
            </div>
        </div>
    )
}


const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        minWidth: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        height: 150,
        minWidth: '80%',
        alignItems: 'center',
        backgroundImage: 'linear-gradient(to right, #5669AC , #67C3D0)',
        paddingLeft: 32,
        paddingRight: 32,
        flexGrow: 'initial'
    },
    headerDoorLogoContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        alignItems: 'center'
    },
    doorLogo: {
        color: 'white',
        height: 64
    },
    title: {
        color: 'white',
        fontSize: 40,
        fontWeight: 'lighter',
        marginLeft: 24
    },
    ktlLogoContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        width: '50%',
        alignItems: 'center'
    },
    ktlLogo: {
        height: 40,
        marginLeft: 28
    },
    hooliLogo: {
        height: 40
    },
    bodyContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: '1 1 auto'
    },
    bodyLeftSideContainer: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
    },
    bodyRightSideContainer: {
        width: '330px',
    }
});
