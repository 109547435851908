
import axiosPost from './axiosPost';

export const gateMonitorGrillaGet = async (arg) => {
    const name = 'Grilla de Personal'
    let result

    const { gateLugarId, fechaDesde } = arg;

    const body = {
        "service": "GateMonitorGrillaGet",
        "params": {
            gateLugarId,
            fechaDesde
        }
    }

    try {
        result = await axiosPost('Y', body, arg)
    } catch (err) {
        console.log(err)
    }

    if (!result.data || result.data === null) {
        console.log(`Error getting ${name}`)
    }

    return result.data.result;
}

export default gateMonitorGrillaGet;
