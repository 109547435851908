
import { useContext, useState, useEffect } from 'react';

import { withStyles, createStyles } from '@material-ui/core/styles';

//Material UI components
import { makeStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Box } from '@material-ui/core';
import gateMonitorCuadranteGet from "../services/GateMonitorCuadranteGet";
import UserContext from "../context/userContext";

export default function MonitorCuadrante(props) {

    const {semanaSeleccionada, puestoSeleccionado} = props;

    //Context cuadrante hook
    const [cuadrantes, setCuadrantes] = useState([]);
    const [filteredRows, setFilteredRows] = useState({})

    const userData = useContext(UserContext);

    useEffect(() => {
        if (semanaSeleccionada && puestoSeleccionado) {
            (async function retrieveCuadrantes() {
                const { hooliToken, sessionId } = userData;
                const cuadrantePayload = {
                    fechaDesde: semanaSeleccionada,
                    gateLugarId: puestoSeleccionado,
                    serviceURL: 'https://rp.hooli.la/hooli-rp/RPServices',
                    hooliToken,
                    sessionId
                }

                let semanasCuadrante = await gateMonitorCuadranteGet(cuadrantePayload)

                setCuadrantes(semanasCuadrante)
            })();
        }
    }, [semanaSeleccionada, puestoSeleccionado]);

    //Filter repeated objects
    useEffect(() => {
        if (cuadrantes.length > 0) {
            const arrayFilter = () => {
                const dias = { 'LU': [], 'MA': [], 'MI': [], 'JU': [], 'VI': [], 'SA': [], 'DO': [] };

                Object.keys(dias).forEach((dia) => {
                    dias[dia] = cuadrantes.filter((item) => {
                        return item.gateCuadriculaFechaDiaNombre === dia
                    })
                })
                setFilteredRows(dias)
            }
            arrayFilter();
        }
    }, [cuadrantes])

    //Styles
    const classes = useStyles();

    const StyledTableCell = withStyles((theme) =>
        createStyles({}),
    )(TableCell);

    const StyledTableRow = withStyles((theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        }),
    )(TableRow);

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <p className={classes.entradaText}>Entrada</p>
                <p className={classes.salidaText}>Salida</p>
                <p className={classes.guardiasText}>Guardias</p>
            </div>

            {
                filteredRows ? <Box className={classes.boxContainer}>
                    {Object.keys(filteredRows).map((item, index) => {
                        return (
                            <div key={index}>
                                <p className={classes.tableTitle}>{`${filteredRows[item][0].gateCuadriculaFechaDiaNombre} ${filteredRows[item][0].gateCuadriculaFecha.substr(8, 2)}/${filteredRows[item][0].gateCuadriculaFecha.substr(5, 2)}`}</p>
                                <TableBody >
                                    {filteredRows[item].map((i, idx) => {
                                        return <StyledTableRow key={idx} style={{ width: '100%' }}>
                                            <StyledTableCell width={155} component="th" scope="row" className={classes.cuadriculaInicio}>
                                                {i.gateCuadriculaInicio === null ? 'Sin servicio' : `${i.gateCuadriculaInicio?.substr(11, 5)}`}
                                            </StyledTableCell>
                                            <StyledTableCell width={120} component="th" scope="row" className={classes.cuadriculaFin}>
                                                {i.gateCuadriculaFin === null ? '' : `${i.gateCuadriculaFin?.substr(11, 5)}`}
                                            </StyledTableCell>
                                            <StyledTableCell width={100} component="th" scope="row" className={classes.cuadriculaMinimo}>
                                                {i.gateCuadriculaMinimo}
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    })}
                                </TableBody>
                            </div>
                        )
                    })}
                </Box> :
                    <div></div>
            }
        </div>
    );
}

const useStyles = makeStyles({
    table: {
        width: '100%',
        backgroundColor: '#FAFAFA'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderTop: '1px white solid',
        backgroundColor: '#FAFAFA'
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#63C3D1',
        width: '100%'
    },
    entradaText: {
        fontSize: 18,
        color: 'white',
        marginLeft: 32,
        fontWeight: 'bold'
    },
    salidaText: {
        fontSize: 18,
        color: 'white',
        fontWeight: 'bold'
    },
    guardiasText: {
        fontSize: 18,
        color: 'white',
        marginRight: 32,
        fontWeight: 'bold'
    },
    boxContainer: {
        maxHeight: '100vh',
        overflow: 'auto'
    },
    tableTitle: {
        fontSize: 22,
        color: '#63C3D1',
        paddingLeft: 32,
        marginBottom: 6
    },
    cuadriculaInicio: {
        height: 6,
        margin: 4,
        padding: 4,
        paddingLeft: 30,
        color: '#707070',
        fontSize: 18,
        backgroundColor: '#FAFAFA'
    },
    cuadriculaFin: {
        height: 6,
        margin: 4,
        padding: 4,
        color: '#707070',
        fontSize: 18,
        backgroundColor: '#FAFAFA'
    },
    cuadriculaMinimo: {
        height: 6,
        margin: 4,
        padding: 4,
        paddingLeft: 20,
        paddingRight: 0,
        color: '#707070',
        fontSize: 18,
        backgroundColor: '#FAFAFA'
    }
});
