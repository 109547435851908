import MonitorGate from './Views/Monitor';
import { UserProvider } from './context/userContext';
import { useEffect, useState } from 'react';

function App() {

  const [user, setUser] = useState(null)

  useEffect(() => {

    (async function myAsync() {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session');
      const hooliToken = urlParams.get('token');
      const merchantId = urlParams.get('merchantId');
      const serviceURL = 'https://rp.hooli.la/hooli-rp/RPServices';

      //@ts-ignore
      setUser({ hooliToken, sessionId, merchantId, serviceURL })
    })();

  }, [])

  return (
    <div>
      <UserProvider value={user}>
        <MonitorGate />
      </UserProvider>
    </div>
  );
}

export default App;