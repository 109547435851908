
import axios from 'axios';

const axiosPost = async (loginReq, body, arg) => {
    let result
    let headers = {
        'Content-Type': 'application/json'
    }

    if (loginReq === 'Y') {

        const { sessionId, hooliToken } = arg;

        headers['x-hooli-token'] = hooliToken

        headers['x-session-id'] = sessionId
    }

    const { serviceURL } = arg

    try {
        result = await axios.post(serviceURL, body, { headers })

    } catch (err) {
        console.log(`Error: ${err}`)
    }

    if (!result || result === null || result === undefined) {
        console.log(`Line28: Error`)
    }

    return result

}

export default axiosPost;