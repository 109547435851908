
import axiosPost from './axiosPost';

export const gateMonitorCuadranteGet = async (arg) => {
    //Retrieve cuadrantes
    const name = 'Grilla cuadrante'
    let result

    const { gateLugarId, fechaDesde } = arg;

    const body = {
        "service": "GateMonitorCuadranteGet",
        "params": {
            "gateLugarId": gateLugarId,
            "fechaDesde": fechaDesde,
        }
    }

    try {
        result = await axiosPost('Y', body, arg)
        // console.log(`Cuadrante Service line 21 ${JSON.stringify(result.data.result)}`)
    } catch (err) {
        console.log(err)
    }


    if (!result.data || result.data === null) {
        console.log(`Error getting ${name}`)
    }

    return result.data.result;
}

export default gateMonitorCuadranteGet;